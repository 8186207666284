<template>
    <div>
        <icon
            class="mr-2"
            :class="iconName"
            :name="iconName"
            color="#8F9EA6"
            size="20" />
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';

export default {
    components: {
        Icon
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        iconName() {
            if (this.item.group_type === 'Organization') {
                return 'building';
            } else {
                return 'user';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.user {
    transform: scale(1.3);
}
</style>